<template>
    <div class="attestation__inner">
        <div class="attestation__head">
            <h2 class="attestation__title">Тестирование</h2>
        </div>
        <div class="attestation__body">
            <form v-if="currentQuestion" class="attestation__question-form" action="">
                <div class="attestation__question">
                    {{currentQuestion.text}}
                </div>
                <span class="attestation__descr">
                    {{currentQuestion.info}}
                </span>
                <ul class="attestation__answer-list">
                    <li v-for="answer in currentQuestion.answers" :key="`answer-${answer.id}`" class="attestation__answer-item">
                        <input
                            @click="clickRadio(answer.id)"
                            v-if="currentQuestion.type === 0"
                            v-model="answer.checked"
                            :value="true"
                            class="attestation__ctrl hidden-ctrl"
                            :id="`answer-${answer.id}`"
                            type="radio"
                        >
                        <input
                            v-else-if="currentQuestion.type === 1"
                            v-model="answer.checked"
                            class="attestation__ctrl hidden-ctrl"
                            :id="`answer-${answer.id}`"
                            type="checkbox"
                        >
                        <label v-if="currentQuestion.multiple !== '2'"
                               class="attestation__ctrl-label" :for="`answer-${answer.id}`">
                            <span class="attestation__ctrl-indicator"></span>
                            {{answer.text}}
                        </label>
                    </li>
                </ul>
                <div class="attestation__btn-wrap justify-content-center mt-50">
                    <button
                        @click="completeQuestion"
                        :disabled="answeredList && !answeredList.length"
                        type="button"
                        class="btn btn-primary-dark btn-rounded attestation__btn-next"
                    >
                        Ответить
                    </button> <!-- атрибут disabled убирается после выбора ответов -->
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    import VueEasyPieChart from 'vue-easy-pie-chart'
    import 'vue-easy-pie-chart/dist/vue-easy-pie-chart.css'
    import functions from "assets/js/functions";
    export default {
        name: "Question",
        components: {
            VueEasyPieChart
        },
        props: ['onComplete', 'onSubmit', 'question'],
        data() {
            return {
                currentQuestion: {
                },
                answersList: [],
                step: 0,
                testId: this.$route.params.test_id,
                courseId: this.$route.params.course_id,
                functions
            }
        },
        computed: {
            answeredList() {
                const {currentQuestion} = this;
                return currentQuestion.answers.filter(answer => answer.checked).map(answer => answer.id);
            },
        },
        methods: {
            clickRadio(answer_id) {
                this.currentQuestion.answers.forEach(item => {
                    if (item.id !== answer_id && item['checked']) {
                        delete item['checked']
                    }
                })
            },
            showPhotoModal() {
                const PhotoModal = () => import(`@/components/accounts/profile/PhotoModal`);
                this.$modal.show(PhotoModal, {
                    image: this.currentQuestion.file,
                    text: ''
                }, {
                    name: 'photo-modal',
                    classes: 'photo-modal'
                })
            },
            //Запуск следующего (или первого) вопроса
            startQuestion() {
                // this.currentQuestion = {...this.questionList[this.step], answered: null};
            },
            //Завершение текущего вопроса
            async completeQuestion() {
                const { currentQuestion } = this,
                    data = {
                        number: currentQuestion.number,
                        answer: {
                            [currentQuestion.id] : this.answeredList
                        }
                    }
                const request = await session.post(`/api/v1/course-test/${this.testId}/next/`, data),
                    requestData = request.data
                if (request.data.finished) {
                    this.completeTesting(requestData);
                } else if (requestData.question) {
                    this.currentQuestion = {
                        number: requestData.number,
                        ...requestData.question
                    };
                }
            },
            //Завершение тестирования при нажатии на кнопку
            manualCompleteQuestion() {
                this.$swal({
                    customClass: {
                        confirmButton: 'btn btn-lg btn-alt-success m-5',
                        cancelButton: 'btn btn-lg btn-alt-danger m-5'
                    },
                    title: 'Завершить тестирование?',
                    text: "Вы ответили не на все вопросы.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Нет',
                }).then(value => {
                    if (value.value) {
                        let swal = this.$swal({
                            title: 'Завершение тестирования',
                            icon: 'info',
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                        });
                        // выполнение
                        this.completeTesting();
                        swal.close();
                    }
                }, reason => {
                    // отказ
                });
            },
            //Завершение тестирования
            completeTesting(data) {
                if (data.is_passed) {
                    if (data.course_finished) {
                        this.$router.push(`/courses/${this.courseId}/finish`);
                    } else {
                        if (data.next_lesson) {
                            this.$router.push(`/courses/${this.courseId}/lesson/${data.next_lesson}`);
                        } else if (data.next_test) {
                            this.$router.push(`/courses/${this.courseId}/test/${data.next_test}`);
                        }
                    }
                } else {
                    this.$router.push(`/courses/${this.courseId}/test/${this.testId}/fail`);
                }
                this.$store.dispatch('sidebar/setSidebarOpened', true);
            },
            //Запуск тестирования
            startTesting(data) {
                this.$store.dispatch('sidebar/setSidebarOpened', false);
            },
        },
        created () {
            this.currentQuestion = {
                number: this.question.number,
                ...this.question.question
            };
            this.startTesting();
        },
        /*async created() {
           try {
               const request = await session.get('/api/v1/testing/question/');
               let {data} = request;
               let questions = data.questions;

               this.timeInSeconds = data.minutes * 60 + data.seconds;
               //timeInSecondsFirst нужна для рассчёта процента от первоначального времени
               this.timeInSecondsFirst = this.timeInSeconds;

               this.timer = setInterval( () => {
                   /!*
                   Если таймер будет у каждого вопроса
                   if (this.currentQuestion.timer === 0) {
                       this.completeQuestion();
                   }
                   this.currentQuestion.timer = this.currentQuestion.timer - 1;*!/
                   if (this.timeInSeconds === 0) {
                       this.completeTesting();
                   }
                   this.timeInSeconds -= 1;
               }, 1000)
               this.startTesting(questions);
           } catch (e) {
               console.error(e);
           }
            window.onbeforeunload = () => "Вы уверены?";
            window.onunload = () => session.post('/api/v1/testing/answer_question/', this.answersList);
       },*/
        beforeDestroy() {
            window.onbeforeunload = null;
            window.onunload = null;
        }
    }
</script>

<style lang="scss">
.attestation {
    &__label {
        font-weight: normal;
        color: #999999;
    }
    &__question {
        margin-bottom: 10px !important;
        width: 100%;
    }
    &__answer-list {
        margin-top: 50px !important;
    }
    &__image {
        @media (max-width: 768px) {
            width: 90vw;
        }
    }
}
</style>
